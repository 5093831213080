import React, { useEffect, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { useNavigate } from "react-router-dom";

export const BreadCrumbComp = ({ setActive }) => {
  const [currentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, [window.location.href]);
  const navigate = useNavigate();

  const [items, setItems] = useState(null);
  const [homeBreadCrumb, setHomeBreadCrumb] = useState(null);

  const _url = [
    "/inicio",
    "/user",
    "/monitoring",
    "/listado",
    "/voicebot",
    "/reporte",
    "/configuration",
    "/config-site",
    "/config-calls",
    "/config-voicebots",
    "/config-audits",
    "/audit",
  ];

  const urlViewSurvey = _url;
  const urlViewCampaña = _url;
  const urlViewSurveyResponse = _url;

  useEffect(() => {
    window.addEventListener("single-spa:routing-event", onRouting);
    return () => {
      window.removeEventListener("single-spa:routing-event", onRouting);
    };
  }, []);

  const onRouting = (customEvent: any): void => {
    let urlUniversal = customEvent.currentTarget.location.origin;
    let url = customEvent.currentTarget.location.pathname;
    const itemsVista = [];
    let label = {};
    if (!urlViewSurvey.includes(url)) {
      label = {
        label: sessionStorage.getItem("surveyName"),
        url: urlUniversal + "/listado",
      };
      itemsVista.push(label);
      setActive("Campañas");
    }

    if (url == "/listado") {
      label = { label: "LISTADO DE CAMPAÑAS", to: urlUniversal + "/listado" };
      itemsVista.push(label);
      setActive("Campañas");
    }

    if (
      !urlViewCampaña.includes(url) &&
      sessionStorage.getItem("campanaName") != "null"
    ) {
      label = {
        label: sessionStorage.getItem("campanaName"),
        url: urlUniversal + "/detalle",
      };
      itemsVista.push(label);
      setActive("Campañas");
    }
    if (
      !urlViewSurveyResponse.includes(url) &&
      sessionStorage.getItem("surveyReponseNumber") != "null"
    ) {
      label = {
        label: sessionStorage.getItem("surveyReponseNumber"),
        url: urlUniversal + "/llamado",
      };
      setActive("Campañas");
      itemsVista.push(label);
    }

    if (url == "/configuration") {
      label = {
        label: "CONFIGURACIÓN",
        to: urlUniversal + "/configuration",
      };
      itemsVista.push(label);
      setActive("Configuración");
    }

    if (url == "/config-site") {
      label = {
        label: "CONFIGURACIÓN SITIO",
        to: urlUniversal + "/config-site",
      };
      itemsVista.push(label);
      setActive("Configuración");
    }

    if (url == "/config-calls") {
      label = {
        label: "CONFIGURACIÓN LLAMADOS",
        to: urlUniversal + "/config-calls",
      };
      itemsVista.push(label);
      setActive("Configuración");
    }

    if (url == "/config-voicebots") {
      label = {
        label: "CONFIGURACIÓN VOICEBOTS",
        to: urlUniversal + "/config-voicebots",
      };
      itemsVista.push(label);
      setActive("Configuración");
    }

    if (url == "/config-audits") {
      label = {
        label: "CONFIGURACIÓN AUDITORÍAS",
        to: urlUniversal + "/config-audits",
      };
      itemsVista.push(label);
      setActive("Configuración");
    }

    if (url == "/monitoring") {
      label = { label: "MONITOREO", to: urlUniversal + "/monitoring" };
      itemsVista.push(label);
      setActive("Monitoreo");
    }
    if (url == "/voicebot") {
      label = { label: "VOICEBOTS", to: urlUniversal + "/voicebot" };
      itemsVista.push(label);
      setActive("Voicebots");
    }
    if (url == "/reporte") {
      label = { label: "ANALYTICS", to: urlUniversal + "/reporte" };
      itemsVista.push(label);
      setActive("Analytics");
    }
    if (url == "/audit") {
      label = { label: "AUDITORÍAS", to: urlUniversal + "/audit" };
      itemsVista.push(label);
      setActive("Auditorías");
    }
    if (urlViewSurvey.includes(url)) {
      setHomeBreadCrumb(null);
      setItems(null);
    }

    if (itemsVista.length > 0) {
      setHomeBreadCrumb({ icon: "pi pi-home", url: urlUniversal + "/inicio" });
      setItems(itemsVista);
    }

    if (url == "/inicio") {
      setActive("Inicio");
    }
  };

  const inConfig = () => {
    if (currentUrl.includes("config-site")) {
      navigate("/configuration");
    }
    if (currentUrl.includes("config-calls")) {
      navigate("/configuration");
    }
    if (currentUrl.includes("config-voicebots")) {
      navigate("/configuration");
    }
    if (currentUrl.includes("config-audits")) {
      navigate("/configuration");
    }
  };

  return (
    <>
      <button
        style={{ border: "0", background: "transparent" }}
        onClick={() => inConfig()}
      >
        {items ? (
          <BreadCrumb
            className="breadCrumbNav layout-breadcrumb viewname"
            model={items}
            home={homeBreadCrumb}
          />
        ) : (
          <div className="breadCrumbMobile">FRONEUS</div>
        )}
      </button>
    </>
  );
};
