import { ShowView } from "../common/components/ShowView";
import { viewTypes } from "../common/types/views.type";
import AppTopbar from "./AppTopbar";
import home from "../icons/home.json";
import bot from "../icons/bot.json";
import campaign from "../icons/campaign.json";
import cog from "../icons/cog.json";
import monitoring from "../icons/monitoring.json";
import chart from "../icons/chart.json";
import brain from "../icons/halfbrain.json";

export const RootMenu = () => {
  const {
    vnConfiguracion,
    vfVerConfiguracion,
    vnMonitoring,
    vfVerMonitoring,
    vnListadoCampana,
    vfVerListadoCampana,
    vnVoicebot,
    vfVerVoicebot,
    vnAnalytics,
    vfVerAnalytics,
    vnAuditorias,
    vfVerAuditorias,
  } = viewTypes;
  const showConfig = ShowView(vfVerConfiguracion, vnConfiguracion);
  const showMonitoring = ShowView(vfVerMonitoring, vnMonitoring);
  const showListados = ShowView(vfVerListadoCampana, vnListadoCampana);
  const showVoicebot = ShowView(vfVerVoicebot, vnVoicebot);
  const showAnalytics = ShowView(vfVerAnalytics, vnAnalytics);
  const showAudit = ShowView(vfVerAuditorias, vnAuditorias);

  const menuItems = [];
  menuItems.push({
    label: "Inicio",
    icon: home,
    path: "/inicio",
  });
  showListados &&
    menuItems.push({
      label: "Campañas",
      icon: campaign,
      path: "/listado",
    });
  showVoicebot &&
    menuItems.push({
      label: "Voicebots",
      icon: bot,
      path: "/voicebot",
    });
  showConfig &&
    menuItems.push({
      label: "Configuración",
      icon: cog,
      path: "/configuration",
    });
  showMonitoring &&
    menuItems.push({
      label: "Monitoreo",
      icon: monitoring,
      path: "/monitoring",
    });
  showAnalytics &&
    menuItems.push({
      label: "Analytics",
      icon: chart,
      path: "/reporte",
    });
  showAudit &&
    menuItems.push({
      label: "Auditorías",
      icon: brain,
      path: "/audit",
    });

  return (
    <div className="nav-user">
      <AppTopbar menuItems={menuItems} />
    </div>
  );
};
